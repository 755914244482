<div class="background-image-container mt-ncs">
   <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap">
        <div>
          <h1 class="display-3">RSES - NIER</h1>
          <p class="lead">
            Institutional Repository of National�Institute for Economic Research
           </p>
        </div>
      </div>
      <p>
        Welcome to the platform of the open repository in the field of economic and social research of the National Institute of Economic Research from Republic of Moldova.
        <br />
        The repository is a digital archive and offers users open access to publications with full text for scientific, educational and methodological purposes. 
	The materials placed in the collections are allowed to be used exclusively non -profit, with the compulsory indication of the author of the work and hyperlinks at this archive.
        <br />
        Consultations and assistance on recording and access to repository can be obtained by phone +373
	22 50- 11- 30 or by e-mail: brts.ince@gmail.com      
	</p>
        </div>
  </div>  
  <picture class="background-image">
    <source type="image/webp" srcset="assets/dspace/images/banner.webp 2000w, assets/dspace/images/banner-half.webp 1200w, assets/dspace/images/banner-tall.webp 768w">
    <source type="image/jpg" srcset="assets/dspace/images/banner.jpg 2000w, assets/dspace/images/banner-half.jpg 1200w, assets/dspace/images/banner-tall.jpg 768w">
    <img alt="" [src]="'assets/dspace/images/banner.jpg'"/><!-- without the []="''" Firefox downloads both the fallback and the resolved image -->
  </picture>
</div>
